<template>
  <NotPermission v-if="!authenticated" />
  <v-container v-else fluid grid-list-lg>
    <Breadcrumbs
      :routes="[{ name: 'Inicio' }, { name: 'Colaborador' }, { name: 'Listado de comentarios' }]"
    />
    <v-card>
      <v-toolbar card color="primary" dark>
        <!-- <v-avatar size="45px">
          <img v-if="avatar" :src="avatar" alt="Avatar" />
        </v-avatar> -->
        <v-toolbar-title>Subir imagen de Item Producto</v-toolbar-title>
      </v-toolbar>    
      <v-form ref="form" v-model="validForm" lazy-validation @submit.prevent="submitImagen">
        <v-container fluid grid-list-lg>
          <v-layout row wrap>
            <v-flex xs12 md12>
              <label class="grey--text text--darken-1">Subir Imagen</label>
              <el-upload
                class="avatar-uploader"
                 style="text-align:center; width:100%;"
                action
                name="imagen"
                :http-request="onFilePicked"
                :show-file-list="false"
                :before-upload="beforeImageUpload"
              >
                <img v-if="imageUrl" :src="imageUrl" class="avatar" />

                <i v-else class="el-icon-plus avatar-uploader-icon" />
              </el-upload>
              <v-fab-transition>
                <v-btn
                  v-show="imageUrl"
                  color="pink"                  
                  dark                  
                  block
                  fab
                  @click="deleteImage()"
                  >Quitar Imagen</v-btn
                >
              </v-fab-transition>                            
            </v-flex>
            <v-flex md6 sm12 xs12>
              <v-text-field
                v-model="form.nombre"
                :disabled="processingForm"
                label="Nombre"
                outline
                :rules="rules.nombre"
                :error="!!formErrors.nombre"
                :error-messages="formErrors.nombre"
                @keyup="
                  () => {
                    formErrors.nombre = undefined;
                    delete formErrors.nombre;
                  }
                "
              />
            </v-flex>
            <v-flex md6 sm12 xs12>
              <v-text-field
                v-model="form.comentario"
                :disabled="processingForm"
                label="Descripcion"
                outline
                :rules="rules.comentario"
                :error="!!formErrors.comentario"
                :error-messages="formErrors.comentario"
                @keyup="
                  () => {
                    formErrors.comentario = undefined;
                    delete formErrors.comentario;
                  }
                "
              />
            </v-flex>
          </v-layout>
        </v-container>
        <center>
          <v-btn
            type="submit"
            color="success"
            :disabled="!validForm || processingForm"
            :loading="processingForm"
            >Guardar</v-btn
          >
          <v-btn
            color="error"
            @click="$router.push({ name: 'ListItemImagen', params: { id: $route.params.id } })"
          >
            Volver
          </v-btn>
        </center>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  metaInfo() {
    return { title: "Crear Item Imagen Producto" };
  },

  components: {
    NotPermission: () => import("@/views/errors/NotPermission"),
    Breadcrumbs: () => import("@/components/Breadcrumbs")
  },
  data() {
    return {
      formErrors: {},
      activeBtn: 2,
      avatar: "",
      imageName: "",
      imageUrl: "",
      imageFile: "",
      file: "",
      form: {
        imagen: "",
        imagen_url: "",
        imagen_path: "",
        tipo: "",
        estado: 1,
        nombre: "",
        comentario: ""
      },
      estados: [
        { id: 0, nombre: "inactivo" },
        { id: 1, nombre: "activo" }
      ],
      tipos: [
        "portada_escritorio",
        "portada_movil",
        "principal_frontal",
        "principal_reverso",
        "carrusel"
      ],
      validForm: true,
      processingForm: false,
      rules: {
        tipo: [v => !!v || "El tipo es requerido"],
        imagen: [v => !!v || "La imagen es requerida"]
      }
    };
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/check",
      user: "auth/user"
    })
  },
  methods: {
    ...mapActions({
      createImagen: "items/createImagen"
    }),
    deleteImage() {
      this.imageUrl = null;
    },
    pickFile() {
      this.$refs.image.click();
    },
    onFilePicked(e) {
      this.form.imagen = e.file;
      if (e.file !== undefined) {
        this.imageName = e.file.name;
        if (this.imageName.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(e.file);
        fr.addEventListener("load", () => {
          this.imageUrl = fr.result;
          this.imageFile = e.file;
        });
      } else {
        this.imageName = "";
        this.imageFile = "";
        this.imageUrl = "";
      }
    },
    beforeImageUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isJPG) {
        this.$notify.info({
          title: "Info",
          message: "La imagen tiene que estar en formato JPG ó PNG"
        });
        return isJPG && isLt5M;
      }
      if (!isLt5M) {
        this.$notify.info({
          title: "Info",
          message: "La imagen no puede exceder los 5MB de tamaño"
        });
        return isJPG && isLt5M;
      }
      return isJPG && isLt5M;
    },
    submitImagen() {
      if (!this.$refs.form.validate()) return false;

      let formData = new FormData();
      formData.append("imagen", this.form.imagen);
      formData.append("nombre", this.form.nombre);
      formData.append("comentario", this.form.comentario);
      this.processingForm = true;
      this.createImagen({ itemId: this.$route.params.id, data: formData })
        .then(response => {
          this.processingForm = false;
          this.$router.push({ name: "ListItemImagen", params: { id: this.$route.params.id } });
        })
        .catch(error => {
          this.processingForm = false;
        });
    }
  }
};
</script>
